import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { addMinutes, format } from 'date-fns';
import { MotionConfig, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import config from 'src/config';
import * as yup from 'yup';
import Button from '../components/Button';
import Drawer from '../components/Drawer';
import FormInputPanel from '../components/FormInputPanel';
import OrderItem from '../components/OrderItem';
import {
  ChevronLeftIcon, LogoIcon,
  SloganIcon,
  SupportAgentIcon,
} from '../components/icons';
import { useAlert } from '../contexts/Alert.context';
import { useYupValidationResolver } from '../hooks/useYupValidatorResolver';
import { getOrdersByPhone } from '../services/api';
import HelpButton from './components/HelpButton';

const phoneSchema = yup.object({
  cellphone: yup.object({
    number: yup.number()
      .required('El teléfono es obligatorio'),
    country_code: yup.string()
      .matches(
        /^\+\d+$/gi,
        { message: 'Código de país inválido' },
      ),
  }).test('test number', (value, { createError }) => {
    const isValidNumber = yup.number().min(1000000000).isValidSync(value.number);

    if (!value) return false;
    if (isValidNumber) return true;
    return createError({
      message: 'Debe ser un número válido',
    });
  }),
});

function SearchOrder() {
  const { phone: currentPhone = {} } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const { showAlert } = useAlert();
  const resolver = useYupValidationResolver(phoneSchema);
  const methods = useForm({
    defaultValues: {
      cellphone: {
        number: '',
        area: '57',
      },
    },
    mode: 'onChange',
    resolver,
  });
  const {
    mutate: getOrders,
    isLoading,
    // isError,
  } = useMutation((phone) => getOrdersByPhone(phone), {
    onSuccess: (data) => {
      if (data.length === 0) {
        showAlert({
          description: 'No encontramos ninguna orden asociada al teléfono ingresado. Porfavor revisa los datos.',
          title: 'No hay ordenes asociadas',
          type: 'error',
          show: true,
          onClose: () => methods.reset(),
        });
      }
      setOrders(data);
    },
  });

  useEffect(() => {
    if (orders.length === 1) {
      navigate(`/order/${orders[0].order_id}/status`);
    }
  }, [orders.length]);

  useEffect(() => {
    if (currentPhone?.number) {
      getOrders(currentPhone?.number);
    }
  }, [currentPhone?.number]);

  const handleSubmit = (data) => {
    getOrders(data.cellphone.number);
  };

  const handleGoToOrder = (orderId) => navigate(`/order/${orderId}/status`);
  const handleResearch = () => navigate('/');
  // const handleResearch = () => {
  //   setOrders([]);
  //   methods.reset({
  //     'cellphone.number': '',
  //   });
  // };
  const preparationEta = 20;
  const deliveryETA = 25;

  return (
    <MotionConfig transition={{ duration: 0.5 }}>
      <motion.div
        className={
          clsx(
            'fixed bg-baco-primary top-0 bottom-0 w-screen px-4 pt-12',
            'h-screen overflow-y-hidden overflow-x-hidden z-10',
          )
        }
        initial={{ left: '100%' }}
        animate={{ left: 0 }}
        exit={{ left: '100%' }}
      >
        <div className="w-full max-w-4xl mx-auto">
          <SloganIcon
            className="absolute top-5 -right-5 w-[72px] h-[72px] fill-white"
          />

          <h2 className="text-white text-4xl mt-0 mb-10 font-medium">
            Haz seguimiento a tus pedidos
          </h2>

          <FormProvider {...methods}>
            <form
              className="bg-baco-secondary-100 rounded-lg py-6 px-4 mb-10"
              onSubmit={methods.handleSubmit(handleSubmit)}
              noValidate
            >
              <h3 className="text-baco-primary text-base font-medium mb-6 mt-0">
                Ingresa tu número celular
              </h3>

              <FormInputPanel
                id="user-information"
                panelElements={[{
                  type: 'tel',
                  name: 'cellphone',
                  placeholder: 'Ingresa tu número de celular',
                  classes: ['mb-6'],
                  fullWidth: true,
                  size: 'md',
                }]}
              />

              <Button
                type="submit"
                size="lg"
                fullWidth
                isLoading={isLoading}
                disabled={!methods.formState?.isValid}
              >
                Ver órdenes
              </Button>
            </form>
          </FormProvider>

          <p className="w-full text-center mt-10 mb-20">
            <Link
              className="text-white text-base underline font-bold"
              to="/"
            >
              Regresar al inicio
            </Link>
          </p>

          <hr className="border-b-2 border-t-0 border-dashed border-b-white my-4" />

          <HelpButton className="mt-6 mb-12 font-semibold" />
        </div>

        <Drawer
          open={orders.length > 1}
          decorator={false}
          square
          fullWidth
          direction="bottom"
          classes={{
            drawer: '!p-0',
            content: 'h-full w-full max-w-4xl mx-auto',
          }}
          scrollBar={false}
        >
          <nav className="flex justify-between py-6 px-4 lg:w-[80%] lg:mx-auto
          fixed top-0 left-0 right-0 lg:static"
          >
            <button type="button" onClick={() => navigate(-1)}>
              <ChevronLeftIcon
                className="fill-baco-primary scale-150"
              />
            </button>
            <button type="button" onClick={handleResearch}>
              <LogoIcon
                className="fill-baco-primary w-5 h-8 scale-110"
              />
            </button>
          </nav>
          <div className="w-full flex justify-center">
            <span
              className="text-baco-primary text-xl text-center font-bold mb-6 mt-20 lg:mt-2"
            >
              Tus pedidos activos
            </span>
          </div>
          <div className="w-full h-[calc(100%-80px)] overflow-y-auto p-4 pb-28 lg:h-auto lg:pb-0">
            {orders.slice(0).reverse().map((order) => (
              <OrderItem
                key={order.order_id}
                orderId={order.order_id}
                name={order.type === config.deliveryType.delivery ? order.address.label : order.store?.name}
                status={order.type === config.deliveryType.delivery ? 'Domicilio' : 'Pick Up'}
                totalProducts={order.items.reduce((total, item) => total + item.quantity, 0)}
                pickDate={format(new Date(order.creationDate), 'dd - MMM - yyyy, hh:mm a')}
                className="mb-6"
                onGoToOrder={() => handleGoToOrder(order.order_id)}
                finished={
                  format(addMinutes(new Date(order?.creationDate), preparationEta + deliveryETA), "yyyy-MM-dd'T'HH:mm")
                  < format(new Date(), "yyyy-MM-dd'T'HH:mm")
                    ? 'SUCCESS' : 'NOT'
                  }
              />
            ))}
          </div>
          <div className="flex justify-center pb-10 pt-6 px-4 shadow-[0px_-5px_18px_rgba(15,89,69,0.1)]
              w-full fixed bottom-0 left-0 right-0 bg-white lg:static lg:shadow-none"
          >
            <Button
              fullWidth
              size="md"
              className="py-3.5 font-bold underline"
              onClick={() => window.open(config.whatsAppSupport, '_blank')}
            >
              <span className="flex justify-center">
                <SupportAgentIcon className="mr-2" />
                ¿Necesitas ayuda?
              </span>
            </Button>
          </div>
        </Drawer>
      </motion.div>
    </MotionConfig>
  );
}

export default SearchOrder;
