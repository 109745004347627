const prefix = 'ecomm ';

function processCategories(categories) {
  return categories.map((category) => {
    const updatedCategory = { ...category };
    const lowerCaseName = updatedCategory.name.toLowerCase();
    const lowerCasePrefix = prefix.toLowerCase();

    if (lowerCaseName.startsWith(lowerCasePrefix)) {
      updatedCategory.name = updatedCategory.name.slice(prefix.length);
    }

    return updatedCategory;
  });
}

export default processCategories;
