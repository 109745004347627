import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '../config';
import setupInterceptorsTo from './setupInterceptorsTo';

// Default ecom-be client
const axiosInstance = axios.create({
  baseURL: config.apiBase,
});

axiosInstance.interceptors.request.use((configuration) => configuration, (error) => Promise.reject(error));
axiosInstance.interceptors.response.use((response) => response, (error) => Promise.reject(error));

const HelperAxios = setupInterceptorsTo(axiosInstance);

axiosRetry(axiosInstance, {
  // retries: 3,
  // retryDelay: (retryCount) => retryCount * 1000,
  // retryCondition: (error) => {
  //   const status = error.response?.status;
  //   return status > 500;
  // },
});

// oauth client
const authInstance = axios.create({
  baseURL: config.oauthApiBase,
});

authInstance.interceptors.request.use((configuration) => configuration, (error) => Promise.reject(error));
authInstance.interceptors.response.use((response) => response, (error) => Promise.reject(error));

const authAxios = setupInterceptorsTo(authInstance, false);

axiosRetry(authInstance, {
  // retryDelay: (retryCount) => retryCount * 1000,
  // retryCondition: (error) => {
  //   const status = error.response?.status;
  //   return status === 401 || status >= 500;
  // },
});

export default {};
export const ecomBe = HelperAxios;
export const oauth = authAxios;
