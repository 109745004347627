import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import Button from '../../../components/Button';
import FormInputPanel from '../../../components/FormInputPanel';
import IllustratedMessage from '../../../components/IllustratedMessage';
import { DeleteIcon } from '../../../components/icons';
import { useShoppingCart } from '../../../hooks/actions/useShoppingCart';
import { useAmplitude } from '../../../hooks/useAmplitude';
import { formatCurrency } from '../../../utils/currency';
import { getDescriptionFromModifiers } from '../../../utils/string';

function Overview(props) {
  const {
    items,
    discount: appliedDiscount,
    itemsNotAvailable,
    removeProduct,
    addDiscount,
  } = useShoppingCart();
  const {
    store,
    isLoading,
    onSubmit,
  } = props;

  const methods = useForm({
    defaultValues: { items, discount: appliedDiscount },
  });

  useEffect(() => {
    methods.reset({
      items,
    });
  }, [items]);

  const { items: products, discount } = useWatch({
    control: methods.control,
  });

  const { trackViewCart, trackConfirmCart } = useAmplitude().useCartPageEvents();

  useEffect(() => {
    const { productIds, totalQuantity, totalPrice } = products.reduce((acc, currentProduct) => {
      acc.productIds.push(currentProduct.id);
      acc.totalQuantity += currentProduct.count;
      acc.totalPrice += currentProduct.price * currentProduct.count;
      return acc;
    }, { productIds: [], totalQuantity: 0, totalPrice: 0 });
    trackViewCart(productIds, totalQuantity, totalPrice);
  }, [products]);

  const total = products.reduce((sum, product) => sum + product.count * product.total, 0);
  let totalWithDiscount = 0;

  if (discount) {
    totalWithDiscount = discount.type === 'percentage'
      ? (total * ((100 - discount.value) / 100))
      : (total - discount.value);
  }

  const handleRemoveItem = (index, item) => removeProduct(index, item);
  const handleSubmit = (data) => {
    trackConfirmCart();
    if (data.discount) {
      addDiscount(data.discount);
    }

    onSubmit({
      ...data,
      total,
      totalWithDiscount,
    });
  };

  const getItemCard = (item, disabled) => {
    const description = getDescriptionFromModifiers(item.modifierGroups);

    return (
      <div className="flex w-full gap-2">
        <div
          className={
            clsx(
              'min-w-[55px] h-[50px] bg-baco-background bg-cover bg-center rounded-lg grow',
              { 'opacity-40': disabled },
            )
          }
          style={{ backgroundImage: `url(${item.imageUrl})` }}
        />
        <div className="flex flex-col w-full grow">
          <div className="flex justify-start items-center space-x-4">
            <h3
              className={
                clsx(
                  'text-base font-bold',
                  { 'text-gray-400': disabled },
                )
              }
            >
              {item.name}
            </h3>
          </div>
          <p
            className={
              clsx(
                'text-xs text-left text-gray-700 mb-2',
                { '!text-gray-300': disabled },
              )
            }
          >
            {
              description.length >= 90
                ? description.substring(0, 80).concat('...')
                : description
            }
          </p>
          <h4
            className={
              clsx(
                'text-sm font-normal mb-3',
                { 'text-gray-300': disabled },
              )
            }
          >
            {formatCurrency(item.total)}
          </h4>
          {disabled && (
            <p className="text-xs text-left text-baco-error-normal mb-2">
              ¡Lo sentimos! Este producto ya no está disponible.
            </p>
          )}
        </div>
      </div>
    );
  };

  if (items.length === 0) {
    return (
      <IllustratedMessage
        className="mt-20"
        title="Carrito vacío"
        description="Agrega productos a tu carrito antes de continuar"
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col h-full mb-5" onSubmit={methods.handleSubmit(handleSubmit)}>
        <div
          className="px-4 h-full overflow-y-auto pb-6"
        >
          <hr className="w-full h-[1px] border-none mt-6 mb-8 bg-gray-300" />
          <FormInputPanel
            id="cart-overview"
            panelElements={items.map((item, index) => ({
              type: 'counter',
              label: getItemCard(item),
              direction: 'row',
              name: `items.${index}.count`,
              min: 0,
              fullWidth: true,
              classes: ['mb-5'],
              size: 'lg',
              hiddenOptions: (
                <div className="flex items-center justify-center px-5 h-full">
                  <Button
                    type="button"
                    onClick={() => handleRemoveItem(index, item)}
                    color="danger"
                    className="p-4"
                    size="md"
                  >
                    <DeleteIcon className="mt-1 h-4 w-4 fill-red-700" />
                  </Button>
                </div>
              ),
            }))}
          />
          {itemsNotAvailable.map((item) => (
            <div key={item.id}>
              {getItemCard(item, true)}
            </div>
          ))}
          <hr className="w-full h-[1px] border-none my-8 bg-gray-300" />
          <FormInputPanel
            id="cart-discount"
            panelElements={[{
              type: 'discount',
              label: !discount ? (
                <span className="text-base font-bold">
                  ¿Tienes un código de descuento?
                </span>
              ) : (
                <div className="flex flex-col items-center justify-center">
                  <h2 className="text-baco-primary text-base font-bold text-center">
                    ¡Felicitaciones!
                  </h2>
                  <p className="text-baco-primary text-base font-bold text-center">
                    Aplicaste un código de descuento
                  </p>
                </div>
              ),
              placeholder: 'Ingresa el código',
              name: 'discount',
              fullWidth: true,
              classes: ['mb-5'],
              storeID: store.storeId,
            }]}
          />
        </div>
        <div className="w-full px-4 pt-4 pb-8 bg-white box-shadow-up">
          <Button
            type="submit"
            isLoading={isLoading}
            className="!text-base"
            size="lg"
            fullWidth
          >
            <span className="mr-2">Continuar |</span>
            {totalWithDiscount > 0
              ? (
                <>
                  <span className="line-through text-gray-200 mr-2">
                    {formatCurrency(total)}
                  </span>
                  <span className="font-bold">
                    {formatCurrency(totalWithDiscount)}
                  </span>
                </>
              ) : formatCurrency(total)}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
Overview.propTypes = {
  store: PropTypes.any,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

Overview.defaultProps = {
  store: {},
  onSubmit: () => null,
  isLoading: false,
};

export default Overview;
