import PropTypes from 'prop-types';
import clsx from 'clsx';
import Skeleton from '../Skeleton';
import { formatCurrency } from '../../utils/currency';
import ProductImage from './ProductImage';
import ProductTags from './ProductTags';

function ProductItem(props) {
  const {
    isLoading,
    imageUrl,
    id,
    name,
    description,
    price,
    discountedPrice,
    onCart,
    tags,
    isAvailable,
    onClick,
  } = props;

  const handleClick = () => onClick(id);

  if (isLoading) {
    return (
      <div
        className="p-3 flex flex-col bg-gray-50 hover:border-baco-primary-700 border-baco-primary-400 border
        rounded-[22px] overflow-hidden gap-3 w-full h-full"
      >
        <div className="h-[243px] bg-cover bg-center rounded-[10px] overflow-hidden bg-baco-primary-400">
          <Skeleton type="rect" className="h-full w-full rounded-r-none" />
        </div>
        <div className="flex flex-col w-full gap-2">
          <Skeleton className="h-6 w-8/12 mb-2" />
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-5 w-4/12 mt-2" />
        </div>
      </div>
    );
  }

  return (
    <button
      type="button"
      className={clsx(
        'p-3 flex flex-col bg-gray-50 hover:border-baco-primary-700 border-baco-primary-400 border',
        'transition-shadow hover:shadow-md rounded-[22px] overflow-hidden w-full h-full focus:outline-2 ',
        'outline-2 outline-transparent focus:outline-baco-primary-500 gap-3 group relative',
        { 'hover:!shadow-none pointer-events-none cursor-default border-baco-primary-100': !isAvailable },
      )}
      aria-disabled={!isAvailable}
      tabIndex={!isAvailable ? -1 : 0}
      onClick={(e) => {
        if (!isAvailable) e.preventDefault();

        handleClick();
      }}
    >
      <ProductImage imageUrl={imageUrl} name={name} isAvailable={isAvailable} />
      <ProductTags tags={tags} isAvailable={isAvailable} />

      <figcaption className="flex flex-col w-full h-full justify-between flex-1">
        <header className="self-start place-self-start justify-self-start max-w-full">
          <div
            className={clsx('text-base flex w-full gap-1 text-center justify-between font-bold', {
              '!text-gray-400': !isAvailable,
            })}
          >
            {onCart > 0 && isAvailable && (
              <span
                className={clsx(
                  'self-center h-5 w-5 text-center leading-5 rounded-full',
                  'font-bold text-baco-secondary bg-baco-primary text-[10px]',
                )}
              >
                {onCart}
              </span>
            )}

            <span className="text-start">{name}</span>
          </div>
        </header>

        <div className="block flex-1 max-w-full">
          <p
            className={
              clsx(
                'px-1 pt-2 text-left text-sm md:text-xs text-gray-700 line-clamp-2',
                { '!text-gray-400': !isAvailable },
              )
            }
          >
            {!description.startsWith('-') ? description : ''}
          </p>
        </div>
      </figcaption>

      <footer className="rounded-b-lg border-t border-baco-primary-100 pt-3 pb-1 w-full">
        <div
          className={clsx('flex justify-start items-center space-x-2', {
            '!text-gray-400 pointer-events-none': !isAvailable,
          })}
        >
          <h4 className="text-sm font-bold">
            {formatCurrency(discountedPrice && discountedPrice < price ? discountedPrice : price)}
          </h4>
          {discountedPrice && discountedPrice < price ? (
            <h5 className={clsx('text-xs font-bold text-gray-500 line-through', { '!text-gray-300': !isAvailable })}>
              {formatCurrency(price)}
            </h5>
          ) : null}
        </div>
      </footer>
    </button>
  );
}

ProductItem.propTypes = {
  isLoading: PropTypes.bool,
  imageUrl: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
  isAvailable: PropTypes.bool,
  onCart: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      bgColor: PropTypes.string,
      fontColor: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
};
ProductItem.defaultProps = {
  isLoading: false,
  imageUrl: null,
  name: '',
  description: '',
  discountedPrice: null,
  isAvailable: false,
  onCart: 0,
  price: null,
  tags: [],
  onClick: () => null,
};

export default ProductItem;
