import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../../components/Button';
import FormInputPanel from '../../../components/FormInputPanel';
import Loader from '../../../components/Loader';
import { LoginScreens, useLogin } from '../../../contexts/Login.context';
import { useYupValidationResolver } from '../../../hooks/useYupValidatorResolver';
import { getInfoEmail } from '../../../services/api';

const userInfoSchema = yup.object({
  email: yup.string()
    .required('El correo electrónico es obligatorio')
    .email('Debes escribir un correo válido').test('checkIsEmailTaken', async (value, { createError }) => {
      if (!value) return false;
      if (!value.includes('@')) return createError({ message: 'Debes escribir un correo válido' });
      const { data } = await getInfoEmail(value);
      const isEmailAvaliable = !data;

      if (isEmailAvaliable) return true;
      return createError({
        message: 'El correo electrónico ya se encuentra en uso.'
        + '<a class="underline text-baco-primary ml-1 font-semibold cursor-pointer">Inicia sesión</a> si deseas',
      });
    }),
  password: yup.string()
    .required('La contraseña es obligatoria').min(8, 'La contraseña es muy corta'),
  passwordConfirmation: yup.string().required('La contraseña es obligatoria')
    .min(8, 'La contraseña es muy corta')
    .oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
  authorize: yup.bool()
    .equals([true], 'Debes autorizar la política de tratamiento de datos.')
    .required('Debes autorizar la política de tratamiento de datos.'),
});

function FormCreateAccountSecondStep(props) {
  const {
    data, onSubmit, isLoading, error,
  } = props;
  const { handleChangeScreen } = useLogin();
  const resolver = useYupValidationResolver(userInfoSchema);

  const methods = useForm({
    defaultValues: data,
    mode: 'onChange',
    resolver,
  });

  const emailVal = methods.watch('email');

  const handleSubmit = (_data) => {
    onSubmit(_data);
  };

  const handleGoToLogin = () => {
    handleChangeScreen(LoginScreens.LOGIN, 'left', false);
  };

  const handleClickAnchorLogin = (e) => {
    e.preventDefault();
    handleGoToLogin();
  };

  useEffect(() => {
    const errorEmailMsg = document.getElementById('error-email');
    if (!errorEmailMsg) return;

    const redirectLink = errorEmailMsg.getElementsByTagName('a')[0];
    if (!redirectLink) return;

    redirectLink.addEventListener('click', handleClickAnchorLogin);

    return () => {
      redirectLink.removeEventListener('click', handleClickAnchorLogin);
    };
  }, [emailVal]);

  if (isLoading) {
    return (<Loader />);
  }

  return (
    <div className="w-full flex flex-col justify-start items-center flex-1 h-full">
      <div className="py-10 h-full px-3 w-full md:w-1/2  lg:w-2/5">
        <h2 className="text-baco-primary font-bold text-3xl mb-2">Crear cuenta</h2>
        <p className="text-gray-700">
          Si ya tienes cuenta,
          <button
            className="underline text-baco-primary ml-1 font-semibold"
            type="button"
            onClick={handleGoToLogin}
          >
            inicia sesión aquí
          </button>
        </p>

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleSubmit)}
            className="flex flex-col h-full justify-between md:justify-start gap-y-4"
            noValidate
          >
            <div>
              <FormInputPanel
                id="login"
                panelElements={[{
                  type: 'text',
                  name: 'email',
                  placeholder: 'Ingresa tu correo electrónico',
                  fullWidth: true,
                  classes: ['my-4'],
                },
                {
                  type: 'password',
                  name: 'password',
                  placeholder: 'Contraseña',
                  fullWidth: true,
                  classes: ['my-4'],
                  autoComplete: 'new-password',
                  helper: <span className="text-gray-700 text-xs">Mínimo 8 caracteres</span>,
                },
                {
                  type: 'password',
                  name: 'passwordConfirmation',
                  placeholder: 'Confirmar contraseña',
                  autoComplete: 'new-password',
                  fullWidth: true,
                  classes: ['my-4'],
                },
                {
                  type: 'checkbox',
                  name: 'authorize',
                  label: (
                    <p>
                      <span>Autorizo el tratamiento de datos para que sean tratados acorde a la </span>
                      <a
                        href="https://www.bacu.co/tratamiento-de-datos"
                        className="text-baco-primary underline font-semibold"
                        target="_blank"
                        rel="noreferrer"
                      >
                        política de tratamiento de datos.
                      </a>
                    </p>
                  ),
                  fullWidth: true,
                  direction: 'row-reverse',
                  required: true,
                },
                ]}
              />
            </div>
            <div className="mb-12 md:mt-12">
              {error
              && (
              <span className="text-center text-baco-error-normal mb-5 block">
                Ha ocurrido un error.
              </span>
              )}

              <Button
                type="submit"
                size="lg"
                fullWidth
                disabled={!methods.formState.isValid}
              >
                Ir a pagar
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

FormCreateAccountSecondStep.propTypes = {
  data: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.any.isRequired,
};

export default FormCreateAccountSecondStep;
