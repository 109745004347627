import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from 'src/config';
import { useLogin } from 'src/contexts/Login.context';
import useDelivery from 'src/hooks/actions/useDelivery';
import useShoppingCart from 'src/hooks/actions/useShoppingCart';
import useAmplitude from 'src/hooks/useAmplitude';
import { UNAVAILABLE_CHANNELS } from 'src/constants';
import CompositeButton from '../components/CompositeButton';
import { IcoBike, IcoStore, LogoIcon } from '../components/icons';
import { getLocations } from '../services/api';
import ViewWithAd from '../templates/ViewWithAd';

function Home() {
  const navigate = useNavigate();
  const { updateDelivery } = useDelivery();
  const { token } = useLogin();
  const { clearCart } = useShoppingCart();
  const { trackSelectChannel } = useAmplitude().useLandingPageEvents();

  // const handleOpenOrders = () => {
  //   navigate('/order/search');
  // };

  const handleShowStores = async () => {
    clearCart();
    updateDelivery({ isDelivery: false, orderType: config.deliveryType.pickup });
    trackSelectChannel('pick_up');
    navigate('/stores');
  };

  const handleShowAddress = () => {
    clearCart();
    updateDelivery({ isDelivery: true, orderType: config.deliveryType.delivery });
    trackSelectChannel('delivery');
    if (token) {
      navigate('/MyAddress');
    } else {
      navigate('/AddressInput');
    }
  };
  const handleGoHome = () => navigate('/');

  useEffect(() => {
    updateDelivery({ isDelivery: false });
  }, []);

  // eagerly load locations, so on the next screen it's already loaded
  useQuery(['locations'], getLocations, {
    refetchOnWindowFocus: false,
  });

  // Logo button to go home
  const iconButton = () => (
    <button
      className="flex w-5 h-5 justify-self-start items-center"
      style={{ marginLeft: 'auto', marginRight: 'auto', width: 'auto  ' }}
      type="button"
      onClick={handleGoHome}
    >
      <LogoIcon
        className="fill-baco-primary w-5 h-8 scale-[1.2]"
        size="3xl"
      />
    </button>
  );

  const header = () => (
    <>
      <div className="flex flex-col gap-y-4 w-4/6 pt-2 px-2">
        <nav className="flex justify-between pt-0 px-8">
          {iconButton()}
        </nav>
      </div>

      <p className="text-baco-primary text-xl text-center italic mb-6" />
    </>
  );

  return (
    <motion.div
      className="fixed w-screen h-full overflow-auto"
      transition={{ duration: 0.5 }}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
    >
      <ViewWithAd
        advertisement={(
          <div
            className="absolute lg:right-2 w-full h-full bg-cover bg-no-repeat
              bg-center flex items-center justify-center bg-blend-saturation xl:right-4"
            style={{
              backgroundImage:
                'url(/images/banners/banner.background.png)',
            }}
          >
            <div
              className="w-full max-w-2xl h-full bg-no-repeat bg-center bg-contain"
              style={{
                backgroundImage:
                  'url(/images/banners/banner.text.png)',
              }}
            />
          </div>
        )}
      >
        {/* View For Desktop */}
        <div className="hidden min-w-[640px] h-full lg:flex flex-col items-center justify-center gap-y-4">
          {header()}

          <div className="flex flex-col gap-y-2 w-[370px]">
            <CompositeButton
              title="Pick Up"
              subtitle="Ordena en linea y recoge en tienda"
              icon={<IcoStore className="h-8 mt-1" />}
              onClick={handleShowStores}
              isUnavailable={UNAVAILABLE_CHANNELS === 'pick_up'}
            />
            <CompositeButton
              title="Domicilio"
              subtitle="Un domiciliario llevará tu pedido"
              icon={<IcoBike className="h-8 mt-2" />}
              onClick={handleShowAddress}
              isUnavailable={UNAVAILABLE_CHANNELS === 'delivery'}
            />
            {/* Esta sección se desactiva hasta tener perfil de usuario */}
            {/* <Button
              size="lg"
              color="secondary"
              fullWidth
              className="mb-4 !bg-transparent"
              onClick={handleOpenOrders}
            >
              <span className="inline-block w-full text-base text-center font-bold underline text-baco-primary">
                Ver pedidos activos
              </span>
            </Button> */}
          </div>
        </div>

        {/* View For Mobile */}
        <div className="flex lg:hidden flex-col items-center justify-center pt-4 gap-y-4">
          {header()}

          <div className="flex flex-col gap-y-2 w-full pt-2 px-2">
            <CompositeButton
              title="Pick Up"
              subtitle="Ordena en linea y recoge en tienda"
              icon={<IcoStore className="h-8 mt-1" />}
              onClick={handleShowStores}
              isUnavailable={UNAVAILABLE_CHANNELS === 'pick_up'}
            />
            <CompositeButton
              title="Domicilio"
              subtitle="Un domiciliario llevará tu pedido"
              icon={<IcoBike className="h-8 mt-2" />}
              onClick={handleShowAddress}
              isUnavailable={UNAVAILABLE_CHANNELS === 'delivery'}
            />

            {/* Esta sección se desactiva hasta tener perfil de usuario */}
            {/* <Button
              size="lg"
              color="secondary"
              fullWidth
              className="mb-4 !bg-transparent"
              onClick={handleOpenOrders}
            >
              <span className="inline-block w-full text-base text-center font-bold underline text-baco-primary">
                Ver pedidos activos
              </span>
            </Button> */}
            <div className="py-2" />
          </div>
        </div>
      </ViewWithAd>
    </motion.div>
  );
}

export default Home;
