import PropTypes from 'prop-types';

function ProductTags({ tags, isAvailable }) {
  return (
    <div className="inline-block absolute top-[10px] right-[10px] place-self-start space-x-1 m-2 z-10">
      {tags.map((tag) => (
        <span
          className="px-2 py-1 rounded-md text-xs"
          style={
            isAvailable
              ? { backgroundColor: tag.bgColor, color: tag.fontColor }
              : { backgroundColor: '#bbbbbb', color: '#fff' }
          }
          key={tag.label}
        >
          {tag.label}
        </span>
      ))}
    </div>
  );
}

ProductTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      bgColor: PropTypes.string,
      fontColor: PropTypes.string,
    }),
  ).isRequired,
  isAvailable: PropTypes.bool.isRequired,
};

export default ProductTags;
