import PropTypes from 'prop-types';

function ProductAvailability({ isAvailable }) {
  if (isAvailable) {
    return null;
  }

  return (
    <div className="grid place-content-end absolute top-0 left-0 w-full h-full text-gray-100 text-end bg-gray-600/50">
      <span
        className="bg-baco-warning/90 border border-baco-error-normal
      text-baco-yellow-light text-xs font-medium m-2 px-2 py-1 rounded"
      >
        Producto No Disponible
      </span>
    </div>
  );
}

ProductAvailability.propTypes = {
  isAvailable: PropTypes.bool.isRequired,
};

export default ProductAvailability;
