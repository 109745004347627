import PropTypes from 'prop-types';
import ProductAvailability from './ProductAvailability';

function ProductImage({ imageUrl, name, isAvailable }) {
  return (
    <div
      className="relative h-[234px] w-full overflow-hidden rounded-[10px] grid place-items-center
      bg-baco-yellow-light group-hover:bg-baco-yellow-light transition-background-color"
    >
      {imageUrl ? (
        <div className="overflow-hidden w-full h-full flex">
          <img
            className="w-full object-cover scale-[.95] object-center group-hover:scale-100 transition-transform"
            src={imageUrl}
            decoding="async"
            alt={`Imagen de ${name}`}
          />
        </div>
      ) : (
        <div className="overflow-hidden w-full h-full flex flex-col justify-center gap-1 md:gap-3">
          <img className="w-full object-cover scale-[.95] object-center" src="/images/logo_bacu.png" alt="Logo Bacu" />
          <span className="text-baco-primary-700 font-semibold text-center justify-self-end w-full">
            Producto increíble en contrucción
          </span>
        </div>
      )}
      <ProductAvailability isAvailable={isAvailable} />
    </div>
  );
}

ProductImage.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool.isRequired,
};

ProductImage.defaultProps = {
  imageUrl: null,
};

export default ProductImage;
