import { useQuery } from '@tanstack/react-query';
import config from 'src/config';
import { getCompleteMenu } from 'src/services/api';

function useCompleteMenu(storeID, selectedCategoryType) {
  const {
    data: menu = { menuCategories: [] },
    isLoading,
    isError,
  } = useQuery([
    'getCompleteMenu', storeID, selectedCategoryType,
  ], async () => {
    const calculatedCategory = selectedCategoryType || config.defaultCategory;

    return getCompleteMenu(storeID, calculatedCategory);
  }, {
    enabled: !!storeID,
    refetchOnWindowFocus: false,
  });

  return { menu, isLoading, isError };
}

export function useMenu({
  storeID,
  searchValue = '',
  selectedCategoryType,
}) {
  const {
    menu: completeMenu,
    isLoading: isMenuLoading,
    isError: isMenuError,
  } = useCompleteMenu(storeID, selectedCategoryType);

  if (isMenuLoading || isMenuError) {
    return { menu: { menuCategories: [] }, isLoading: isMenuLoading, isError: isMenuError };
  }

  const filterProducts = [];
  completeMenu.menuCategories?.forEach((category) => {
    const filteProduct = category.products.filter((product) => {
      const productLower = product.name.toLowerCase();
      const categoryLower = (product.categoryGroup?.name || '').toLowerCase();
      const valueLower = searchValue.toLowerCase().trim();

      return productLower.includes(valueLower) || categoryLower.includes(valueLower);
    });

    if (Array.isArray(filteProduct) && filteProduct.length) {
      filterProducts.push({ ...category, products: filteProduct });
    }
  });

  return {
    menu: {
      ...completeMenu,
      menuCategories: filterProducts.sort((a, b) => a.index - b.index),
    },
  };
}

export default useMenu;
