import { useMutation } from '@tanstack/react-query';
import { restoreForgot } from '../../services/api';

export function useForgotRestore() {
  const {
    mutate: forgotRestore,
    isLoading: isLoadingForgotRestore,
    error,
    isError,
  } = useMutation(({ password, hash }) => restoreForgot({ password, hash }), {
    retry: 0,
  });

  return {
    isLoadingForgotRestore, forgotRestore, error, isError,
  };
}

export default useForgotRestore;
