import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import Button from 'src/components/Button';
import HeaderWithHelp from 'src/components/HeaderWithHelp';
import Modal from 'src/components/Modal';
import {
  ChevronDownIcon, ChevronUpIcon,
  DeliveryArriveIcon, DeliveryCreatedIcon, DeliveryFindingCourierIcon, DeliveryInProgressIcon,
  DeliveryOnRouteIcon, DeliveryProcessingIcon, DeliveryStepper1Icon,
  DeliveryStepper2Icon, DeliveryStepper3Icon, DeliveryStepper4Icon, DeliveryStepper5Icon,
} from 'src/components/icons';
import { useSession } from 'src/hooks/actions/useSession';
import { useUser } from 'src/hooks/actions/useUser';
import useCreatedInPOS from 'src/hooks/services/useCreatedInPOS';
import formatCurrency from 'src/utils/currency';
import config from '../../config';
import ConfirmationSuccess from './ConfirmationSuccess';

const orderStatuses = {
  PROCESSING: {
    id: 'PROCESSING',
    title: 'Estamos revisando tu orden',
    icon: <DeliveryProcessingIcon />,
    iconTitle: '',
    ETAtitle: 'Por favor espera un momento mientras confirmamos tu pedido.',
    ETAHour: '',
  },
  CREATED: {
    id: 'CREATED',
    title: '¡Estamos preparando tu pedido!',
    stepper: <DeliveryStepper1Icon className="scale-105" />,
    icon: <DeliveryCreatedIcon />,
    iconTitle: '',
    ETAtitle: 'Tu pedido llegará a partir de las:',
    ETAHour: '03:25 pm',
    ETAChange: false,
    ETAChangeTitle: '¡Oops! Tuvimos que ajustar el horario de entrega. Ahora tu pedido llegará a partir de las:',
  },
  FINDING_COURIER: {
    id: 'FINDING_COURIER',
    title: 'Estamos buscando un repartidor...',
    stepper: <DeliveryStepper2Icon className="scale-110 lg:scale-125" />,
    icon: <DeliveryFindingCourierIcon />,
    iconTitle: '',
    ETAtitle: 'Tu pedido llegará a partir de les:',
    ETAHour: '03:25 pm',
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    title: '¡Hemos asignado un repartidor!',
    stepper: <DeliveryStepper3Icon className="scale-105" />,
    icon: <DeliveryInProgressIcon />,
    iconTitle: '',
    ETAtitle: 'Tu pedido llegará a partir de las:',
    ETAHour: '03:25 pm',
  },
  ON_THE_ROUTE: {
    id: 'ON_THE_ROUTE',
    title: '¡Repartidor en camino!',
    stepper: <DeliveryStepper4Icon className="scale-105" />,
    icon: <DeliveryOnRouteIcon />,
    iconTitle: '',
    ETAtitle: 'Tu pedido llegará a partir de las:',
    ETAHour: '03:25 pm',
  },
  ARRIVE: {
    id: 'ARRIVE',
    title: '¡El repartidor llegó a tu domicilio!',
    stepper: <DeliveryStepper5Icon className="scale-105" />,
    icon: <DeliveryArriveIcon />,
    iconTitle: '',
    ETAtitle: 'Tu pedido llegó a las:',
    ETAHour: '03:25 pm',
  },
};

function OrderStatusDelivery({ order }) {
  const [orderStatus] = useState(orderStatuses.PROCESSING);
  const [showDetails, setShowDetails] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const handleHelp = () => window.open(config.whatsAppSupport, '_blank');
  const onClose = () => setShowModal(false);
  const handleToggleDetails = () => setShowDetails((prev) => !prev);
  const { orderID } = useParams();
  const { orderInPOS, isAuthError, isError } = useCreatedInPOS({ orderID });
  const { changeSessionToken, changeRefreshToken } = useSession();
  const navigate = useNavigate();
  const { updateUser } = useUser();

  useEffect(() => {
    if (isAuthError) {
      changeSessionToken(null);
      changeRefreshToken(null);
      updateUser({ phone: {} });

      navigate('/order/search');
    }
  }, [isError]);

  if (orderInPOS?.is_created_in_pos) {
    return <ConfirmationSuccess order={order} />;
  }

  return (
    <div className="bg-baco-primary w-screen min-h-screen flex flex-col items-center">
      {/* V2 - stepper: */}
      {/* <div className="bg-white rounded-b-3xl shadow-[0_4px_4px_rgba(0,0,0,0.1)] w-full max-w-[430px]"> */}
      <div className="bg-white h-screen w-full max-w-[430px]">
        <HeaderWithHelp />
        <div className="flex flex-col justify-start items-center">
          <span className="text-baco-primary text-base font-bold mx-auto mb-5">
            {orderStatus.title}
          </span>
          {orderStatus.stepper}
          <div className="mt-6">
            {orderStatus.icon}
          </div>
          <div className="flex flex-col py-6">
            <span className={clsx(
              'text-baco-primary text-sm text-center mx-auto px-12',
              {
                'text-baco-warning': orderStatus.ETAChange,
              },
            )}
            >
              {!orderStatus.ETAChange ? orderStatus.ETAtitle : orderStatus.ETAChangeTitle}
            </span>
            <span className={clsx(
              'text-baco-primary text-center mx-auto font-extrabold text-[20px] font-kranaFatB',
              {
                'text-baco-warning': orderStatus.ETAChange,
              },
            )}
            >
              {orderStatus.ETAHour}
            </span>
          </div>
        </div>
      </div>
      <div
        className={
              clsx('transition-all max-h-0 overflow-hidden px-4 mt-4 w-full max-w-[430px]', {
                'max-h-[30px]': !showDetails,
                'max-h-full': showDetails,
              })
            }
      >
        <h2
          className={
                clsx('flex justify-between items-center w-full', {
                  'mb-1': showDetails,
                  'mb-2': !showDetails,
                })
              }
        >
          <span className="text-lg text-white font-bold">
            Detalles del Pedido
          </span>
          <button
            className="text-white"
            onClick={handleToggleDetails}
            type="button"
          >
            {
              showDetails
                ? (
                  <span className="flex justify-start items-center gap-2">
                    <ChevronDownIcon className="h-9 w-9" />
                  </span>
                )
                : (
                  <span className="flex justify-start items-center gap-2">
                    <ChevronUpIcon className="h-9 w-9" />
                  </span>
                )
            }
          </button>
        </h2>
        {
          Object.keys(order).length !== 0 && (
            <>
              <div className="text-white text-sm mb-4">
                <ul>
                  Nombre:
                  {' '}
                  <b>{order.customer.name}</b>
                </ul>
                <ul>
                  Dirección:
                  {' '}
                  <b>{order.address.label}</b>
                </ul>
                <ul>
                  N° de orden:
                  {' '}
                  <b>{(order.paymentDetails.dataPayment.transactions.id).slice(-4)}</b>
                </ul>
              </div>
              <h2 className="text-lg text-white font-bold mb-1">Productos</h2>
              <ul className="text-white text-sm mb-6">
                {order?.items?.map((item) => (
                  <li key={item.product?.id} className="flex flex-col justify-between mb-1">
                    <span className="flex justify-between mb-2">
                      <span className="w-10">
                        {`${item.quantity}x`}
                      </span>
                      <span className="w-full">
                        {item.product?.name}
                      </span>
                    </span>
                    <ul className="pl-10">
                      {(item.itemGroups || [])
                        .flatMap((itemGroup) => itemGroup.modifiers)
                        .map((modifier) => (
                          <li key={modifier.id} className="flex justify-between">
                            <span className="w-10">
                              {`${modifier.quantity}x`}
                            </span>
                            <span className="w-full">
                              {modifier.name}
                            </span>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
                <li className="flex flex-col items-end justify-end mt-2 pt-2 border-t-2 border-t-white">
                  <div className="mt-1">
                    <span className="text-sm">
                      Subtotal
                    </span>
                    <span className="pl-2 text-sm">
                      {formatCurrency(order.paymentDetails?.dataPayment?.amount?.value || 0)}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm">
                      Costo de envío
                    </span>
                    <span className="pl-2 text-sm">
                      {formatCurrency(Number(process.env.REACT_APP_DELIVERY_COST))}
                    </span>
                  </div>
                  <div>
                    <strong>
                      Total
                    </strong>
                    <strong className="pl-2">
                      {formatCurrency(Number(order.paymentDetails?.dataPayment?.amount?.value)
                    + Number(process.env.REACT_APP_DELIVERY_COST) || 0)}
                    </strong>
                  </div>
                </li>
              </ul>
            </>
          )
          }
      </div>
      {showModal && (
        <Modal
          className="w-[90%]"
          show={showModal}
          onClose={onClose}
        >
          <div className="flex flex-col">
            <div className="text-2xl font-bold">¡Oops!</div>
            <div className="py-4">
              No hemos podido encontrar un repartidor en este momento. ¿Quieres seguir esperando hasta
              que encontremos uno?
            </div>
            <div className="py-2">
              <Button />
              <Button
                size="md"
                fullWidth
                className="!text-base rounded-2xl"
                onClick={() => {
                  // what should it do?
                }}
              >
                <span className="mr-2">Seguir buscando</span>
              </Button>
            </div>
            <div>
              <Button
                className="w-full text-baco-carbon underline py-10"
                style={{
                  backgroundColor: 'transparent',
                  hover: 'black',
                  active: 'transparent',
                }}
                onClick={handleHelp}
              >
                <span>Contactarme con soporte</span>
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default OrderStatusDelivery;

OrderStatusDelivery.propTypes = {
  order: PropTypes.object.isRequired,
};
