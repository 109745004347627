import { useQuery } from '@tanstack/react-query';
import config from '../../config';
import { getConfigCategories } from '../../services/api';
import { getCurrentDayMinutes, getMinutesOfDay } from '../../utils/date';

const MIN_MS = 60000;

export function useConfigCategories({
  storeID,
}) {
  const {
    data: configCategories = [],
    isLoading: isLoadingConfig,
  } = useQuery(['getConfigCategories'], () => getConfigCategories(), {
    enabled: !!storeID,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  const availableCategories = configCategories.filter((categoryConfig) => {
    const { fastSchedule: { to, from } } = categoryConfig;
    const minTime = config.superFast.minTime / MIN_MS;
    const tto = getCurrentDayMinutes() + minTime;

    if (tto <= 60 * 24) {
      return tto < getMinutesOfDay(to);
    }

    const tfrom = tto % (60 * 24);

    return tfrom >= getMinutesOfDay(from);
  });

  return { isLoadingConfig, availableCategories };
}

export default useConfigCategories;
