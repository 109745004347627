import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useYupValidationResolver } from '../../../hooks/useYupValidatorResolver';
import Button from '../../../components/Button';
import FormInputPanel from '../../../components/FormInputPanel';
import { useLogin, LoginScreens } from '../../../contexts/Login.context';

const userInfoSchema = yup.object({
  firstName: yup.string()
    .required('El nombre es obligatorio').min(2, 'El nombre es muy corto'),
  lastName: yup.string().required('El apellido es obligatorio')
    .min(2, 'La apellido es muy corto'),
  cellphone: yup.object({
    number: yup.number().required(),
  }).test('test number', (value, { createError }) => {
    const isValidNumber = yup.number().min(1000000000).isValidSync(value.number);

    if (!value) return false;
    if (isValidNumber) return true;
    return createError({
      message: 'Debe ser un número válido',
    });
  }),

});

function FormCreateAccountFirstStep(props) {
  const { data, onSubmit } = props;
  const { handleChangeScreen } = useLogin();
  const resolver = useYupValidationResolver(userInfoSchema);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: data,
    mode: 'onChange',
    resolver,
  });

  const handleSubmit = (_data) => {
    onSubmit(_data);
  };

  const handleGoToLogin = () => {
    navigate('/login');
    handleChangeScreen(LoginScreens.LOGIN, 'left', false);
  };

  return (
    <div className="w-full flex flex-col justify-start items-center flex-1 h-full overflow-auto">
      <div className="py-10 h-full px-3 w-full md:w-1/2 lg:w-2/5 overflow-auto">
        <h2 className="text-baco-primary font-bold text-3xl mb-2">Crear cuenta</h2>
        <p className="text-gray-700">
          Si ya tienes cuenta,
          <button
            className="underline text-baco-primary ml-1 font-semibold"
            type="button"
            onClick={handleGoToLogin}
          >
            inicia sesión aquí
          </button>
        </p>

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleSubmit)}
            className="flex flex-col gap-y-4 h-full justify-between md:justify-start"
            noValidate
          >
            <div>
              <FormInputPanel
                id="login"
                panelElements={[{
                  type: 'text',
                  name: 'firstName',
                  placeholder: 'Ingresa tu nombre',
                  fullWidth: true,
                  classes: ['my-4'],
                },
                {
                  type: 'text',
                  name: 'lastName',
                  placeholder: 'Ingresa tu apellido',
                  fullWidth: true,
                  classes: ['my-4'],
                },
                {
                  type: 'tel',
                  name: 'cellphone',
                  placeholder: 'Ingresa tu número celular',
                  fullWidth: true,
                  classes: ['my-4'],
                },
                ]}
              />
            </div>
            <Button
              type="submit"
              size="lg"
              fullWidth
              className="mb-12 md:mt-12"
              disabled={!methods.formState.isValid}
            >
              Continuar
            </Button>
          </form>
        </FormProvider>

      </div>
    </div>
  );
}

FormCreateAccountFirstStep.propTypes = {
  data: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormCreateAccountFirstStep;
