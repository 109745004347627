import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormNewPassword from './FormNewPassword';

function Recover() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    if (!searchParams.has('hash')) {
      navigate('/login');
    }
  }, [search]);

  const hash = searchParams.get('hash');

  return <FormNewPassword hash={hash} />;
}

export default Recover;
