import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CHANNELS } from 'src/constants';

const useChannelAvailability = (unavailableChannel) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    const unavailablePaths = CHANNELS[unavailableChannel] || [];
    const isUnavailable = unavailablePaths.some((path) => currentPath.includes(path));

    if (isUnavailable) {
      navigate('/');
    }
  }, [location, navigate, unavailableChannel]);
};

export default useChannelAvailability;
