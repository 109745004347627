import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { ChevronDownIcon } from '../icons';

function getScrollParent(childrenItem) {
  if (!childrenItem) {
    return window;
  }

  const props = window.getComputedStyle(childrenItem);
  if (['auto', 'scroll'].includes(props.getPropertyValue('overflow-y'))) {
    return childrenItem;
  }

  return getScrollParent(childrenItem.parentElement);
}

function CustomSelect(props) {
  const {
    className,
    size,
    value,
    valueKey,
    renderValue,
    renderOption,
    onChange,
    fullWidth,
    options,
    fieldState,
    fluid,
    ...args
  } = props;

  const {
    invalid,
    isDirty,
  } = fieldState;

  const [direction, setDirection] = useState('down');
  const [showOptions, setShowOptions] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const closeFn = (event) => {
      if (containerRef && !containerRef.current.parentElement.contains(event.target)) {
        setShowOptions(false);
      }
    };

    window.addEventListener('click', closeFn);

    return () => {
      window.removeEventListener('click', closeFn);
    };
  }, [containerRef]);

  useEffect(() => {
    if (showOptions && containerRef) {
      const parentContainer = getScrollParent(containerRef.current.parentElement);
      const height = parentContainer.offsetHeight;
      const { offsetTop } = containerRef.current;
      const [{ offsetHeight }] = containerRef.current.children;
      const isSpaceDown = offsetTop + offsetHeight <= height;
      const isSpaceUp = offsetTop - offsetHeight > 0;

      setDirection(!isSpaceDown && isSpaceUp ? 'up' : 'down');
    }
  }, [showOptions, containerRef]);

  const handleOpenOptions = () => {
    setShowOptions(true);
  };

  const handleChange = (option) => {
    setShowOptions(false);
    onChange(option[valueKey]);
  };

  return (
    <div
      className={
        clsx(
          'w-[280px]',
          {
            '!w-full': fullWidth,
            relative: !fluid,
          },
        )
      }
    >
      <button
        {...args}
        type="button"
        onClick={handleOpenOptions}
        className={
          clsx(
            'relative border w-full disabled:opacity:60 rounded outline outline-0',
            className,
            {
              'px-4 py-2 pr-10': size === 'sm',
              'p-4 pr-11': size === 'md',
              'p-6 pr-12': size === 'lg',
              'rounded-b-none': showOptions,
              'bg-gray-100 border-gray-100 text-black': !(invalid && isDirty),
              'bg-baco-error-light border-baco-error-normal text-baco-error-normal': invalid && isDirty,
            },
          )
        }
      >
        <div
          className={
            clsx('relative w-full', {
              'min-h-[16px] text-sm': size === 'sm',
              'min-h-[20px] text-base': size === 'md',
              'min-h-[24px] text-lg': size === 'lg',
            })
          }
        >
          {renderValue(value)}
        </div>
        <ChevronDownIcon
          className={
            clsx(
              'absolute right-2 top-0 bottom-0 m-auto',
              {
                'fill-baco-error-normal': invalid && isDirty,
                'h-4 w-4': size === 'sm',
                'h-5 w-5': size === 'md',
                'h-6 w-6': size === 'lg',
              },
            )
          }
        />
      </button>
      <div
        className={
          clsx(
            'absolute w-full h-0',
            {
              'bottom-0': direction === 'down',
              'top-0': direction === 'up',
            },
          )
        }
        ref={containerRef}
      >
        <div
          className={
            clsx(
              'absolute flex flex-col w-full max-h-[200px] shadow-md rounded-b-md bg-white overflow-y-auto',
              {
                'top-0': direction === 'down',
                'bottom-0': direction === 'up',
                hidden: !showOptions,
              },
            )
          }
        >
          {options.map((option) => (
            <button
              className="w-full p-2 hover:bg-gray-100"
              key={option.name}
              onClick={() => handleChange(option)}
              type="button"
            >
              {renderOption(option)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

CustomSelect.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.any,
  valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  fieldState: PropTypes.any,
  renderValue: PropTypes.func,
  renderOption: PropTypes.func,
  fluid: PropTypes.bool,
};

CustomSelect.defaultProps = {
  className: '',
  size: 'md',
  value: '',
  valueKey: 'value',
  onChange: () => null,
  fullWidth: false,
  options: [],
  fieldState: {},
  renderValue: (value) => value,
  renderOption: (option) => option.label,
  fluid: false,
};

export default CustomSelect;
