import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import useDelivery from 'src/hooks/actions/useDelivery';
import Button from '../../../components/Button';
import FormInputPanel from '../../../components/FormInputPanel';
import config from '../../../config';
import { useStore } from '../../../hooks/actions/useStore';
import { useAmplitude } from '../../../hooks/useAmplitude';
import { formatCurrency } from '../../../utils/currency';
import { getPaymentTypeName, paymentTypeSkipName } from '../../../utils/string';

function PaymentMethod(props) {
  const {
    payments,
    enrollPayments,
    paymentMethod,
    onSubmit,
    isLoading,
    total,
    totalWithDiscount,
  } = props;
  const methods = useForm({
    defaultValues: {
      payment_method: paymentMethod,
    },
  });
  const currPaymentMethod = useWatch({
    control: methods.control,
    name: 'payment_method',
  });
  const { trackSelectPaymentMethod } = useAmplitude().useCheckoutPageEvents();

  const { deliveryData } = useDelivery();
  const { selectedStore } = useStore();

  useEffect(() => {
    methods.reset({
      payment_method: currPaymentMethod,
      isEnrollment: false,
    });
  }, [currPaymentMethod]);
  const handleSubmit = (data) => {
    trackSelectPaymentMethod(data.payment_method);
    onSubmit(data);
  };
  const enrolledPaymentMethods = payments.filter((payment) => payment.vaulted_token && payment.type === 'CARD');
  const clickToPayMethod = payments.find((payment) => payment.type === 'CLICK_TO_PAY');
  const regularPaymentMethods = payments.filter(
    (payment) => !(payment.vaulted_token && payment.type === 'CARD') && payment.type !== 'CLICK_TO_PAY',
  );

  const clickToPay = clickToPayMethod && (
    <>
      <p className="font-bold text-center mt-2">Pago express</p>
      <FormInputPanel
        id="payment-method"
        panelElements={[{
          type: 'paymentMethod',
          name: 'payment_method',
          options: [{
            icon: <img
              height={30}
              width={300}
              src="/images/photos/click_to_pay.png"
              alt={clickToPayMethod.name}
              className="w-[125px]"
            />,
            label: paymentTypeSkipName(clickToPayMethod.type) ? null : clickToPayMethod.description,
            value: `${clickToPayMethod.type}:`,
          }],
          classes: ['!bg-black', '!text-white', '!rounded-lg', 'items-center', 'justify-center'],
        }]}
      />

      <br />
    </>
  );

  return (
    <FormProvider {...methods}>
      <div className="relative w-full h-full overflow-y-auto">
        <form className="flex flex-col mb-5" onSubmit={methods.handleSubmit(handleSubmit)}>
          <div className="h-full flex flex-col px-4 pb-[80px]">
            {enrolledPaymentMethods.length > 0 && (
              <>
                <p>
                  Tus métodos de pago
                </p>
                <FormInputPanel
                  id="payment-method"
                  panelElements={[{
                    type: 'paymentMethod',
                    name: 'payment_method',
                    options: enrolledPaymentMethods.map((payment, index) => ({
                      icon: <img width={30} height={30} src={payment.icon} alt={payment.name} />,
                      label: payment.description,
                      value: `${payment.type}:${payment.vaulted_token}`,
                      subOption: (
                        !payment.vaulted_token && enrollPayments.find((ep) => ep.type === payment.type)
                      ) ? (
                        <FormInputPanel
                          id={index}
                          panelElements={[{
                            type: 'checkbox',
                            name: 'isEnrollment',
                            label: 'Guardar para tu siguiente compra',
                            fullWidth: true,
                            direction: 'row-reverse',
                          }]}
                        />
                        ) : undefined,
                    })),
                    classes: ['mb-3'],
                  }]}
                />
              </>
            )}
            <p className="mt-6 mb-2">
              {enrolledPaymentMethods.length === 0
                ? 'Selecciona el método de pago'
                : 'Selecciona otro método de pago'}
            </p>

            {clickToPay}

            <FormInputPanel
              id="payment-method"
              panelElements={[{
                type: 'paymentMethod',
                name: 'payment_method',
                options: regularPaymentMethods.map((payment, index) => ({
                  icon: <img width={30} height={30} src={payment.icon} alt={payment.name} />,
                  label: getPaymentTypeName(payment.type),
                  value: `${payment.type}:`,
                  subOption: (
                    !payment.vaulted_token && enrollPayments.find((ep) => ep.type === payment.type)
                  ) ? (
                    <FormInputPanel
                      id={index}
                      panelElements={[{
                        type: 'checkbox',
                        name: 'isEnrollment',
                        label: 'Guardar para tu siguiente compra',
                        fullWidth: true,
                        direction: 'row-reverse',
                      }]}
                    />
                    ) : undefined,
                })),
                classes: ['mb-3'],
              }]}
            />
          </div>
          <div className="fixed z-10 w-full max-w-xl bottom-0 px-4 pt-4 pb-8 bg-white box-shadow-up">
            <Button
              isLoading={isLoading}
              className="!text-base"
              type="submit"
              disabled={!currPaymentMethod}
              size="lg"
              fullWidth
            >
              <span className="mr-2">Pagar |</span>
              {totalWithDiscount > 0
                ? (
                  <>
                    <span className="line-through text-gray-200 mr-2">
                      {!deliveryData.isDelivery ? formatCurrency(total)
                        : formatCurrency(total + Number(selectedStore.shipping_cost))}
                    </span>
                    <span className="font-bold">
                      {!deliveryData.isDelivery ? formatCurrency(totalWithDiscount)
                        : formatCurrency(totalWithDiscount + Number(selectedStore.shipping_cost))}
                    </span>
                  </>
                ) : formatCurrency(!deliveryData.isDelivery ? total
                  : total + Number(selectedStore.shipping_cost))}
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
}

PaymentMethod.propTypes = {
  payments: PropTypes.any.isRequired,
  enrollPayments: PropTypes.any,
  paymentMethod: PropTypes.string,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  totalWithDiscount: PropTypes.number,
};

PaymentMethod.defaultProps = {
  onSubmit: () => null,
  paymentMethod: config.paymentMethods.CARD,
  enrollPayments: [],
  isLoading: false,
  total: 0,
  totalWithDiscount: 0,
};

export default PaymentMethod;
