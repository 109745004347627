import PropTypes from 'prop-types';
import Button from './Button';
import { ChevronRightIcon } from './icons';

function CompositeButton(props) {
  const {
    title, subtitle, icon, onClick, isUnavailable,
  } = props;

  return (
    <Button
      className="rounded-2xl px-6 py-2"
      size="md"
      fullWidth
      disabled={isUnavailable}
      startIcon={icon}
      endIcon={(
        <ChevronRightIcon className="h-10" />
      )}
      onClick={!isUnavailable ? onClick : () => {}}
    >
      <span className="inline-block align-bottom w-full text-base text-left px-1">
        {title}
      </span>
      <span className="inline-block align-text-top w-full text-xs text-left px-1">
        {subtitle}
      </span>
      {
        isUnavailable && (
          <span className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center p-2
           text-xl font-bold pointer-events-none bg-baco-carbon/60 text-baco-background"
          >
            Temporalmente fuera de servicio
          </span>
        )
      }
    </Button>
  );
}

CompositeButton.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  isUnavailable: PropTypes.bool.isRequired,
};

CompositeButton.defaultProps = {
  subtitle: '',
  icon: null,
  onClick: () => {},
};

export default CompositeButton;
